/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ProductPlaceholderProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const ProductPlaceholder: React.FC<ProductPlaceholderProps> = ({size, ...props}) => (
  <svg viewBox="0 0 32 32" fill="currentColor" width={ size || "32" } height={ size || "32" } {...props}>
    <path fill="currentColor" d="M32 5.269l-.512.278-.491-.278.256.513-.256.491.491-.278.512.278-.278-.491zM29.697 10.688l-.512-.278.278.512-.278.491.512-.234.491.234-.256-.491.256-.512zM29.462 7.744l-.769-.406.406.747-.406.769.769-.406.725.406-.384-.769.384-.747zM26.881 7.681v0l-1.962-5.506-9.6 3.434c.469-.341.919-.769 1.153-1.281.681-1.45.406-2.837-.662-3.328-.213-.106-.469-.172-.703-.172-.897 0-1.75.663-2.263 1.772-.191.406-.278.875-.278 1.344-.3-.362-.662-.681-1.066-.875-.512-.253-1.047-.381-1.537-.381-.853 0-1.516.384-1.813 1.047-.513 1.066.172 2.303 1.622 2.987.384.172.809.278 1.344.278.128 0 .278 0 .406-.022l-11.438 4.094 1.962 5.503 1.238-.447v15.041h24.063v-19.969h-10.322l9.856-3.519zM13.463 2.922v0c.406-.853 1.047-1.387 1.644-1.387.172 0 .3.022.447.106.703.319.853 1.344.319 2.431-.447.897-1.684 1.472-2.263 1.728-.234-.638-.597-1.981-.147-2.878zM11.137 6.316c-.406 0-.769-.063-1.047-.191-1.088-.513-1.622-1.366-1.281-2.069.191-.406.619-.619 1.172-.619.384 0 .809.106 1.238.3.897.447 1.472 1.684 1.728 2.262-.425.122-1.128.316-1.809.316zM2.453 15.722l-1.494-4.222 11.434-4.097-1.281 2.709.3.128.363.172 1.6-3.35.575-.213 3.331 1.597.3-.619-2.731-1.3 9.663-3.478 1.516 4.203-23.575 8.469zM26.538 12.031v18.303h-22.441v-14.506l10.622-3.797h11.819z"
    />
  </svg>
);
ProductPlaceholder.displayName = 'ProductPlaceholder';
/* tslint:enable */
/* eslint-enable */
