import classnames from 'classnames';
import React, {useRef, useEffect} from 'react';
import {DotNavigation} from 'wix-ui-tpa/cssVars';
import {classes as sliderGalleryStyleable} from './PaginationDots.st.css';
import s from './PaginationDots.scss';

export interface PaginationDotsProps {
  swiperCurrentIndex: number;
  totalProducts: number;
  slidesCount: number;
  shouldLoop: boolean;
  productsAmountPerSwipe: number;
}

export enum PaginationDotsDataHooks {
  PaginationDots = 'PaginationDotsDataHooks.Dots',
  PaginationDotsWrapper = 'PaginationDotsDataHooks.Wrapper',
}

const MAX_DOTS_AMOUNT = 5;
const CENTER_DOT_MIN_INDEX = 3;
const LOOPING_PADDING_DOTS_AMOUNT = 9;
const LOOPING_MIN_DOTS_AMOUNT = MAX_DOTS_AMOUNT + LOOPING_PADDING_DOTS_AMOUNT;

export const PaginationDots = ({
  swiperCurrentIndex,
  totalProducts,
  slidesCount,
  shouldLoop,
  productsAmountPerSwipe,
}: PaginationDotsProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const shouldHideDots = totalProducts <= slidesCount;

  const getDotAmount = () => {
    const swipesAmount = Math.ceil((totalProducts - (slidesCount ?? 0)) / productsAmountPerSwipe + 1);
    return shouldLoop ? Math.max(swipesAmount + LOOPING_PADDING_DOTS_AMOUNT, LOOPING_MIN_DOTS_AMOUNT) : swipesAmount;
  };

  const getSelectedDotIndex = () => {
    return shouldLoop ? swiperCurrentIndex + CENTER_DOT_MIN_INDEX : swiperCurrentIndex;
  };

  // istanbul ignore next: cant test with jsdom, tested by sled
  useEffect(() => {
    const rootWidth = rootRef?.current?.clientWidth;
    if (!rootWidth || !rootRef.current?.classList.contains(s.hideDotsOnSSR)) {
      return;
    }
    setTimeout(() => {
      rootRef.current?.classList.remove(s.hideDotsOnSSR);
    }, 0);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rootRef, rootRef?.current, rootRef?.current?.clientWidth, totalProducts, slidesCount]);

  return (
    <div
      data-hook={PaginationDotsDataHooks.PaginationDotsWrapper}
      role="navigation"
      ref={rootRef}
      className={classnames(
        s.dotNavigationWrapper,
        s.hideDotsOnSSR,
        s.hideDotsOnSSR,
        sliderGalleryStyleable.root,
        s.sidePadding,
        {
          [s.showDots]: !shouldHideDots,
        }
      )}>
      <DotNavigation
        className={'pagination-dots'}
        aria-label={swiperCurrentIndex.toString()}
        data-hook={PaginationDotsDataHooks.PaginationDots}
        currentIndex={getSelectedDotIndex()}
        length={getDotAmount()}
        showBorder={true}
      />
    </div>
  );
};
