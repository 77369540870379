import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {VerticalAlignments} from '../../../types/galleryTypes';
import {CategoryHeaderLayout} from '../../../constants';
import {CSSProperties} from 'react';

/* istanbul ignore file */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const heroCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const showCategoryHeaderImage = styles.booleans.gallery_showCategoryHeaderImage;
  const showCategoryHeaderDescription = styles.booleans.gallery_showCategoryHeaderDescription;
  const showCategoryHeaderName = styles.booleans.gallery_showCategoryHeaderName;
  const categoryHeaderLayout = styles.numbers.gallery_categoryHeaderLayout;
  const textInsideLayout = categoryHeaderLayout === CategoryHeaderLayout.TextInside;
  const categoryHeaderImageMinHeight = showCategoryHeaderImage
    ? `${styles.numbers.gallery_categoryHeaderImageMinHeight}px`
    : 'unset';

  return {
    categoryHeaderImageHeight: textInsideLayout ? '100%' : categoryHeaderImageMinHeight,
    categoryHeaderImageMinHeight,
    showCategoryHeaderImageGap:
      (showCategoryHeaderImage && !textInsideLayout && (isMobile ? '16px' : '24px')) || 'inherit',
    categoryHeaderFlexDirection: getCategoryHeaderFlexDirection(categoryHeaderLayout),
    imageContainerPosition: getImageContainerPosition(categoryHeaderLayout),
    categoryHeaderSpaceBetweenTitleDescription:
      showCategoryHeaderName && showCategoryHeaderDescription
        ? styles.numbers.gallery_categoryHeaderSpaceBetweenTitleDescription.toString()
        : '0',
    categoryCountAndFilterContainerJustifyContent: styles.booleans.gallery_showProductsCounter
      ? 'space-between'
      : 'flex-end',
    categoryDescriptionContainerColor:
      showCategoryHeaderImage && textInsideLayout ? styles.colors.gallery_categoryDescriptionContainerColor : 'unset',
    categoryDescriptionContainerBorderColor:
      showCategoryHeaderImage && textInsideLayout
        ? styles.colors.gallery_categoryDescriptionContainerBorderColor
        : 'unset',
    categoryDescriptionContainerBorderWidth:
      showCategoryHeaderImage && textInsideLayout
        ? `${styles.numbers.gallery_categoryDescriptionContainerBorderWidth}px`
        : 'unset',
    categoryDescriptionContainerBorderStyle: showCategoryHeaderImage && textInsideLayout ? 'solid' : 'unset',
    categoryDescriptionContainerCornerRadius:
      showCategoryHeaderImage && textInsideLayout
        ? `${styles.numbers.gallery_categoryDescriptionContainerCornerRadius}px`
        : 'unset',
    categoryDescriptionContainerPadding: showCategoryHeaderImage && textInsideLayout ? '24px' : 'inherit',
    categoryDescriptionContainerMargin: showCategoryHeaderImage && textInsideLayout ? '24px' : 'inherit',
    categoryInfoVerticalAlignment: textInsideLayout
      ? getAlignItemsPropValue(styles.strings.gallery_categoryInfoVerticalAlignment)
      : 'unset',
  };
};

export const heroShouldRenderVars = ({booleans}: ShouldRenderVarsFnParams) => {
  const showCategoryHeaderImage = booleans.gallery_showCategoryHeaderImage;
  const showCategoryHeaderDescription = booleans.gallery_showCategoryHeaderDescription;
  const showCategoryHeaderName = booleans.gallery_showCategoryHeaderName;

  return {
    showCategoryHeaderNameOrDescription: showCategoryHeaderName || showCategoryHeaderDescription,
    showHeroSection:
      booleans.gallery_showCategoryHeaderSection &&
      (showCategoryHeaderImage || showCategoryHeaderName || showCategoryHeaderDescription),
    showCategoriesBreadcrumbs: booleans.gallery_showCategoriesBreadcrumbs,
  };
};

const getAlignItemsPropValue = (verticalAlignment) => {
  switch (verticalAlignment.value) {
    case VerticalAlignments.TOP:
      return 'start';
    case VerticalAlignments.CENTER:
      return 'center';
    case VerticalAlignments.BOTTOM:
    default:
      return 'end';
  }
};

const getCategoryHeaderFlexDirection = (layout: CategoryHeaderLayout): CSSProperties['flexDirection'] => {
  switch (layout) {
    case CategoryHeaderLayout.TextInside:
      return 'row';
    case CategoryHeaderLayout.TextAbove:
      return 'column-reverse';
    case CategoryHeaderLayout.TextBelow:
    default:
      return 'column';
  }
};

const getImageContainerPosition = (layout: CategoryHeaderLayout): CSSProperties['position'] => {
  switch (layout) {
    case CategoryHeaderLayout.TextInside:
      return 'absolute';
    case CategoryHeaderLayout.TextBelow:
    case CategoryHeaderLayout.TextAbove:
    default:
      return 'relative';
  }
};
