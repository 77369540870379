import * as React from 'react';
import s from '../Carousel.scss';
import {Arrow, ArrowDirection} from './Arrow/Arrow';
import {CarouselAriaLabels} from '../Carousel';
import classNames from 'classnames';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export enum CarouselDesktopDataHooks {
  PreviousArrow = 'left-arrow',
  NextArrow = 'right-arrow',
  Carousel = 'carousel',
  CarouselDesktop = 'carouselWrapper',
}

interface CarouselDesktopProps {
  shouldShowArrowsOnHover: boolean;
  ariaLabels: CarouselAriaLabels;
  slideNext: () => void;
  slidePrev: () => void;
  children?: React.ReactChild[] | React.ReactChild;
}

export const CarouselDesktop: React.FC<CarouselDesktopProps> = (props: CarouselDesktopProps) => {
  const {slidePrev, slideNext, shouldShowArrowsOnHover, ariaLabels} = props;
  const {isRTL} = useEnvironment();

  return (
    <div
      data-hook={CarouselDesktopDataHooks.CarouselDesktop}
      className={classNames(s.carouselWrapper, shouldShowArrowsOnHover ? s.showArrowsOnHover : '')}>
      <Arrow
        ariaLabel={ariaLabels.arrowLeft}
        dataHook={CarouselDesktopDataHooks.PreviousArrow}
        onClick={isRTL ? slideNext : slidePrev}
        onKeyPress={slidePrev}
        direction={ArrowDirection.LEFT}
        className={s.arrow}
      />
      <div className={s.carousel} data-hook={CarouselDesktopDataHooks.Carousel}>
        {props.children}
      </div>
      <Arrow
        ariaLabel={ariaLabels.arrowRight}
        dataHook={CarouselDesktopDataHooks.NextArrow}
        onClick={isRTL ? slidePrev : slideNext}
        onKeyPress={slideNext}
        direction={ArrowDirection.RIGHT}
        className={s.arrow}
      />
    </div>
  );
};
