import React from 'react';
import type {IProduct} from '../../../../types/galleryTypes';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';

import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {
  formattedPrimaryPrice,
  formattedSecondaryPrice,
  hasAutomaticDiscount,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ConditionalRender} from '../../ConditionalRender/ConditionalRender';
import classNames from 'classnames';
import {Experiments} from '../../../../constants';
import {isProductItemHasDiscount, shouldRenderOutOfStock, shouldRenderPrice} from '../productItemUtils';
import {IProductItem} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  SrPriceRange = 'st-price-range',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
  PriceRange = 'price-range-from',
  PricesContainer = 'prices-container',
  BasePriceComponent = 'base-price-component',
}

interface TextMapProps {
  productPriceBeforeDiscountSR: string;
  productOutOfStockText: string;
  productPriceAfterDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
  measurementUnits?: {[key: string]: {[key: string]: string}};
  pricePerUnitSR?: string;
}

export interface IProductPriceProps extends IGalleryGlobalProps {
  product: IProduct;
  selectedProductVariant: IProductItem;
  allowFreeProducts: boolean;
  textsMap: TextMapProps;
  fromPrice?: string;
  shouldUseCommonDiscountPricingMethods: boolean;
}

const getPriceBeforeDiscount = ({
  textsMap,
  secondaryPrice,
  shouldEnableDiscountAndRegularPriceSwapViewer,
}: {
  textsMap: TextMapProps;
  secondaryPrice: string;
  shouldEnableDiscountAndRegularPriceSwapViewer: boolean;
}) => {
  return (
    <>
      <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
        {textsMap.productPriceBeforeDiscountSR}
      </span>
      <span
        data-hook={DataHook.PriceBeforeDiscount}
        className={classNames({
          [s.priceBeforeDiscount]: shouldEnableDiscountAndRegularPriceSwapViewer,
          [s.oldPriceBeforeDiscount]: !shouldEnableDiscountAndRegularPriceSwapViewer,
        })}
        data-wix-original-price={secondaryPrice}>
        {secondaryPrice}
      </span>
    </>
  );
};

class PriceRangeComp extends React.Component<
  {
    formattedFromPrice: string;
    textsMap: TextMapProps;
  } & IProvidedTranslationProps
> {
  public render() {
    const {formattedFromPrice, textsMap, t} = this.props;
    return (
      <>
        <span className={a11y.srOnly} data-hook={DataHook.SrPriceRange}>
          {textsMap.productPriceWhenThereIsNoDiscountSR}
        </span>
        <span data-hook={DataHook.PriceRange} className={classNames(s.priceFrom)}>
          {t('priceRangeText', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }
}

const PriceRange = withGlobals(withTranslations()(PriceRangeComp));

const RegularPrice = ({
  product,
  textsMap,
  shouldUseCommonDiscountPricingMethods,
  shouldEnableDiscountAndRegularPriceSwapViewer,
  shouldCheckDiscountInVariantLevel,
  selectedVariant,
}: {
  product: IProduct;
  textsMap: TextMapProps;
  shouldUseCommonDiscountPricingMethods: boolean;
  shouldEnableDiscountAndRegularPriceSwapViewer: boolean;
  shouldCheckDiscountInVariantLevel: boolean;
  selectedVariant: IProductItem;
}) => {
  const hasDiscount = isProductItemHasDiscount(
    product,
    shouldUseCommonDiscountPricingMethods,
    shouldCheckDiscountInVariantLevel,
    selectedVariant
  );
  const primaryPrice = getPrimaryPrice({
    product,
    hasDiscount,
    shouldUseCommonDiscountPricingMethods,
    shouldCheckDiscountInVariantLevel,
    selectedVariant,
  });

  return (
    <>
      {hasDiscount &&
        getSecondaryPrice({
          product,
          textsMap,
          shouldUseCommonDiscountPricingMethods,
          shouldEnableDiscountAndRegularPriceSwapViewer,
          shouldCheckDiscountInVariantLevel,
          selectedVariant,
        })}
      {
        <>
          <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
            {hasDiscount ? textsMap.productPriceAfterDiscountSR : textsMap.productPriceWhenThereIsNoDiscountSR}
          </span>
          <span
            data-hook={DataHook.PriceToPay}
            className={classNames({
              [s.salePrice]: hasDiscount && shouldEnableDiscountAndRegularPriceSwapViewer,
              [s.oldSalePrice]: hasDiscount && !shouldEnableDiscountAndRegularPriceSwapViewer,
              [s.priceToPay]: !hasDiscount,
            })}
            data-wix-price={primaryPrice}>
            {primaryPrice}
          </span>
        </>
      }
    </>
  );
};

function getSecondaryPrice({
  product,
  textsMap,
  shouldUseCommonDiscountPricingMethods,
  shouldEnableDiscountAndRegularPriceSwapViewer,
  shouldCheckDiscountInVariantLevel,
  selectedVariant,
}: {
  product: IProduct;
  textsMap: TextMapProps;
  shouldUseCommonDiscountPricingMethods: boolean;
  shouldEnableDiscountAndRegularPriceSwapViewer: boolean;
  shouldCheckDiscountInVariantLevel: boolean;
  selectedVariant: IProductItem;
}) {
  if (shouldUseCommonDiscountPricingMethods) {
    const selectedSecondaryPrice = formattedSecondaryPrice(
      product,
      shouldCheckDiscountInVariantLevel ? selectedVariant : undefined
    );
    return getPriceBeforeDiscount({
      textsMap,
      secondaryPrice: selectedSecondaryPrice,
      shouldEnableDiscountAndRegularPriceSwapViewer,
    });
  } else {
    const isDiscountsExist =
      Boolean(product.itemDiscount?.priceAfterDiscount) && Boolean(product.formattedComparePrice);
    const selectedSecondaryPrice = isDiscountsExist ? product.formattedComparePrice : product.formattedPrice;

    return getPriceBeforeDiscount({
      textsMap,
      secondaryPrice: selectedSecondaryPrice,
      shouldEnableDiscountAndRegularPriceSwapViewer,
    });
  }
}

function getPrimaryPrice({
  product,
  hasDiscount,
  shouldUseCommonDiscountPricingMethods,
  shouldCheckDiscountInVariantLevel,
  selectedVariant,
}: {
  product: IProduct;
  hasDiscount: boolean;
  shouldUseCommonDiscountPricingMethods: boolean;
  shouldCheckDiscountInVariantLevel: boolean;
  selectedVariant: IProductItem;
}) {
  if (shouldUseCommonDiscountPricingMethods) {
    const itemDiscount = product.itemDiscount;
    hasAutomaticDiscount(product);
    return formattedPrimaryPrice(
      {...product, itemDiscount},
      shouldCheckDiscountInVariantLevel ? selectedVariant : undefined
    );
  } else {
    const discountPrice = product.itemDiscount?.priceAfterDiscount || product.formattedComparePrice;

    return hasDiscount ? discountPrice : product.formattedPrice;
  }
}

class ProductPriceImpl extends React.Component<IProductPriceProps, any> {
  private renderPrice() {
    const {product, textsMap, fromPrice, shouldUseCommonDiscountPricingMethods, globals, selectedProductVariant} =
      this.props;
    const shouldEnableDiscountAndRegularPriceSwapViewer = globals.useExperiments.enabled(
      Experiments.EnableDiscountAndRegularPriceSwapViewer
    );
    const shouldCheckDiscountInVariantLevel = globals.useExperiments.enabled(
      Experiments.ShouldCheckDiscountInVariantLevel
    );

    return (
      <div
        className={classNames(s.prices, s.pricesMarginTop, {
          [s.pricesFlexProperties]: shouldEnableDiscountAndRegularPriceSwapViewer,
        })}
        data-hook={DataHook.PricesContainer}>
        {fromPrice ? (
          <PriceRange formattedFromPrice={fromPrice} textsMap={textsMap} />
        ) : (
          <RegularPrice
            shouldCheckDiscountInVariantLevel={shouldCheckDiscountInVariantLevel}
            selectedVariant={selectedProductVariant}
            shouldUseCommonDiscountPricingMethods={shouldUseCommonDiscountPricingMethods}
            product={product}
            textsMap={textsMap}
            shouldEnableDiscountAndRegularPriceSwapViewer={shouldEnableDiscountAndRegularPriceSwapViewer}
          />
        )}
      </div>
    );
  }

  public render() {
    const {product, allowFreeProducts, shouldUseCommonDiscountPricingMethods} = this.props;
    const shouldRenderPriceLayout = shouldRenderPrice(
      product,
      allowFreeProducts,
      shouldUseCommonDiscountPricingMethods
    );
    const shouldRenderOutOfStockLayout = shouldRenderOutOfStock(product);

    if (shouldRenderOutOfStockLayout) {
      return (
        <div className={s.priceContainer}>
          <ConditionalRender by={'showTextualAddToCartButton'} className={s.outOfStockPriceContainer}>
            {this.renderPrice()}
          </ConditionalRender>
        </div>
      );
    } else if (shouldRenderPriceLayout) {
      return <div className={s.priceContainer}>{this.renderPrice()}</div>;
    }
    return null;
  }
}

export const ProductPrice = withGlobals(ProductPriceImpl);
