import {EmptyGallery} from '../../../../common/components/EmptyGallery/EmptyGallery';
import React from 'react';
import {withGlobals} from '../../../../globalPropsContext';
import {ISliderGlobalProps} from '../../../sliderGlobalStrategy';

export const EmptySliderGallery = withGlobals(({globals}: ISliderGlobalProps) => {
  const {textsMap} = globals;
  return (
    <EmptyGallery
      localeMap={{
        noProductsFilteredMessageText: '',
        noProductsMessageText: textsMap.noProductsMessageText,
        emptyCategoryEditorSubTitle: textsMap.emptyCategoryEditorSubTitle,
        emptyCategoryEditorTitle: textsMap.emptyCategoryEditorTitle,
      }}
      hasFilters={false}
    />
  );
});
