import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {
  CategoryListHorizontalButtonBorderType,
  CategoryListHorizontalOverflow,
  CategoryListLayout,
} from '../../../constants';
import {CategoryListHeaderBehavior, LongCategoryListLayout} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const categoryTreeCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const shouldIndentCategoryList =
    styles.booleans.gallery_showCategoryListHeader &&
    styles.numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT;

  const shouldLimitLongCategoryList = styles.numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT;
  const verticalCategoryList = styles.numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;

  const getCategoryListItemBoxShadow = (
    borderWidth: number,
    borderType: CategoryListHorizontalButtonBorderType,
    color: string
  ) => {
    switch (borderType) {
      case CategoryListHorizontalButtonBorderType.None:
        return 'unset';
      case CategoryListHorizontalButtonBorderType.Underline:
        return `inset 0 ${borderWidth * -1}px 0 0 ${color}`;
      case CategoryListHorizontalButtonBorderType.Full:
        return `inset 0 0 0 ${borderWidth}px ${color}`;
    }
  };

  return {
    shouldLimitLongCategoryList: shouldLimitLongCategoryList ? 1 : 0,
    collapsedCategoryListDisplay: shouldLimitLongCategoryList ? '-webkit-box' : 'block',
    categoryListIndentationPx: verticalCategoryList && shouldIndentCategoryList ? '28px' : '0px',
    categoryListMarginBottom: verticalCategoryList ? '12px' : '0',
    horizontalCategoryListFlexWrap:
      styles.numbers.gallery_categoryListHorizontalOverflow === CategoryListHorizontalOverflow.Scroll
        ? 'nowrap'
        : 'wrap',
    categoryListHorizontalBaseButtonBoxShadow: getCategoryListItemBoxShadow(
      styles.numbers.gallery_categoryListHorizontalBaseButtonBorderWidth,
      styles.numbers.gallery_categoryListHorizontalBaseButtonBorderType,
      styles.colors.gallery_categoryListHorizontalBaseButtonBorderColor
    ),
    categoryListHorizontalHoverButtonBoxShadow: getCategoryListItemBoxShadow(
      styles.numbers.gallery_categoryListHorizontalHoverButtonBorderWidth,
      styles.numbers.gallery_categoryListHorizontalHoverButtonBorderType,
      styles.colors.gallery_categoryListHorizontalHoverButtonBorderColor
    ),
    categoryListHorizontalSelectedButtonBoxShadow: getCategoryListItemBoxShadow(
      styles.numbers.gallery_categoryListHorizontalSelectedButtonBorderWidth,
      styles.numbers.gallery_categoryListHorizontalSelectedButtonBorderType,
      styles.colors.gallery_categoryListHorizontalSelectedButtonBorderColor
    ),
  };
};

export const categoryTreeShouldRenderVars = ({numbers}: ShouldRenderVarsFnParams) => {
  return {
    categoryListHeaderGoesBackToParent:
      numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT,
    categoryListHeaderDoesNothing: numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.DO_NOTHING,
    shouldLimitCategoryList: numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT,
  };
};
