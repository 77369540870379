/* eslint-disable wix-custom-rules/wixstores-file-names */
import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../baseCustomCssVars';
import {CategoryListItemsToShow, CategoryListLayout} from '../../constants';

export const extendedGridGalleryCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const verticalCategoryList = styles.numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;
  const {gallery_showCategories, galleryShowFilters} = styles.booleans;
  const {gallery_layoutHorizontalPadding} = styles.numbers;
  const showSideFilters = (gallery_showCategories && verticalCategoryList) || galleryShowFilters;

  return {
    extendedGalleryInlineStartPadding: showSideFilters && !isMobile ? '20px' : `${gallery_layoutHorizontalPadding}px`,
  };
};

/* istanbul ignore file */
export const extendedGridGalleryShouldRenderVars = ({numbers, booleans, isMobile}: ShouldRenderVarsFnParams) => {
  const verticalCategoryList = numbers.gallery_categoryListLayout === CategoryListLayout.Vertical && !isMobile;
  const {gallery_showCategories, galleryShowFilters} = booleans;
  const includeSiblingsInCategoryList = numbers.gallery_categoryListItemsToShow === CategoryListItemsToShow.ShowAll;

  return {
    showSideFilters: (gallery_showCategories && verticalCategoryList) || galleryShowFilters,
    showHorizontalCategoryList: gallery_showCategories && !verticalCategoryList,
    showHorizontalCategoryListWhenNoSubcategories:
      gallery_showCategories && !verticalCategoryList && includeSiblingsInCategoryList,
  };
};
