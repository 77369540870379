import React, {Component, ReactNode} from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import type {DropdownOptionProps, DropdownProps} from 'wix-ui-tpa/cssVars';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import s from './ProductOptions.scss';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {classes as dropdownOptionStyles, style as styleDropdown} from './DropdownOption.st.css';
import classNames from 'classnames';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {Experiments, ProductOptionsDiplayLimit} from '../../../../constants';
import {RevealAnimation} from '../../RevealAnimation/RevealAnimation';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ConditionalRender} from '../../ConditionalRender/ConditionalRender';
import {LazyDropDown} from '../../../lazyComponents/lazyWUTComponents';
import {ProductColorOption} from './ProductColorOption/ProductColorOption';

type IProductOption = IProduct['options'][0];

export interface ProductOptionsChangeData {
  optionType: ProductOptionType;
}

interface IProductOptionsState {
  latestActiveOptionId: string;
}

export interface IProductOptionsProps extends IGalleryGlobalProps {
  product: IProduct;
  selectionIds: number[];
  isLoading?: boolean;
  readOnly?: boolean;

  onSelectionIdsChange(
    optionSelections: IProductOptionsProps['selectionIds'],
    changeData: ProductOptionsChangeData
  ): Promise<void>;

  variantsAvailability: IProductSelectionAvailabilityMap;
  isItemHovered: boolean;
}

export interface OptionRenderParams {
  option: IProduct['options'][number];
  availability: IProductSelectionAvailabilityMap[string];
  url?: string;
}

export enum DataHook {
  ProductOption = 'product-option',
  DropdownOption = 'product-options-dropdown',
  ColorOption = 'product-options-color',
  ColorOptionMore = 'product-options-color-more',
  OptionWrapper = 'product-option-wrapper',
  RevealAnimationWrapper = 'product-option-reveal-animation-wrapper',
}

const getAllVisibleDropdownOptions = (options: IProductOption[]): IProductOption[] => {
  const colorOptsCount = getAllVisibleColorOptions(options).length;
  const slotsForDropdowns = ProductOptionsDiplayLimit.totalLimit - colorOptsCount;
  return options.filter(({optionType}) => optionType === ProductOptionType.DROP_DOWN).slice(0, slotsForDropdowns);
};

const getAllVisibleColorOptions = (options: IProductOption[]): IProductOption[] => {
  return options
    .filter(({optionType}) => optionType === ProductOptionType.COLOR)
    .slice(0, ProductOptionsDiplayLimit.colorPickersLimit);
};

const getAllVisibleOptions = (options: IProductOption[]): IProductOption[] => {
  return [...getAllVisibleColorOptions(options), ...getAllVisibleDropdownOptions(options)];
};

export class ProductOptions extends Component<IProductOptionsProps & IProvidedTranslationProps, IProductOptionsState> {
  public state: IProductOptionsState = {
    latestActiveOptionId: '',
  };

  private readonly sealDropdownChangeHandler =
    (optionId: string, optionsSelectionIds: number[]): DropdownProps['onChange'] =>
    (selected) => {
      const {selectionIds, onSelectionIdsChange} = this.props;
      this.setState({latestActiveOptionId: optionId});
      const nextSelectionIds = [
        ...selectionIds.filter((selectionId) => !optionsSelectionIds.includes(selectionId)),
        parseInt(selected.id, 10),
      ];

      void onSelectionIdsChange(nextSelectionIds, {
        optionType: ProductOptionType.DROP_DOWN,
      });
    };

  private shouldRenderLoader(optionId: string): boolean {
    return this.props.isLoading && this.state.latestActiveOptionId !== optionId;
  }

  private readonly renderDropdownOption = ({option, availability}: OptionRenderParams) => {
    const {useExperiments, shouldShowMobile} = this.props.globals;
    const galleryProductOptionsAndQuantityRoundCornersInViewer = useExperiments.enabled(
      Experiments.GalleryProductOptionsAndQuantityRoundCornersInViewer
    );
    const shouldAllowCustomProductOptionsAndQuantityWidth = useExperiments.enabled(
      Experiments.GalleryProductOptionsAndQuantityWidth
    );
    const useDropdownTpaLabel = useExperiments.enabled(Experiments.UseDropdownTpaLabel);

    const {title, selections, id} = option;
    const options: DropdownOptionProps[] = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: availability[selection.id].isSelectable,
        value: selection.description,
      }));

    return (
      <div
        data-hook={DataHook.ProductOption}
        className={classNames({[s.dropdownAlignment]: shouldAllowCustomProductOptionsAndQuantityWidth})}>
        <LazyDropDown
          className={
            /* istanbul ignore next */
            classNames(
              styleDropdown(
                /* istanbul ignore next */
                galleryProductOptionsAndQuantityRoundCornersInViewer
                  ? dropdownOptionStyles.dropdownOption
                  : dropdownOptionStyles.dropdownOptionOld
              ),
              {
                [s.dropdownWidth]: shouldAllowCustomProductOptionsAndQuantityWidth,
                [s.isMobile]: shouldAllowCustomProductOptionsAndQuantityWidth && shouldShowMobile,
              }
            )
          }
          data-hook={DataHook.DropdownOption}
          aria-label={useDropdownTpaLabel ? title : ''}
          placeholder={title}
          options={options}
          onChange={this.sealDropdownChangeHandler(
            id,
            selections.map((selection) => selection.id)
          )}
          isLoading={this.shouldRenderLoader(id)}
          readOnly={this.props.readOnly}
        />
      </div>
    );
  };

  private readonly getColorOptionClass = (isNewMargin: boolean): string => (isNewMargin ? s.color : s.oldColor);

  private readonly renderAllColorOptions = (colorOptions: IProductOption[]) => {
    const {
      variantsAvailability,
      product,
      globals: {productsManifest, useExperiments},
      selectionIds,
      onSelectionIdsChange,
    } = this.props;

    const shouldUseNewMarginAndPadding = useExperiments.enabled(Experiments.EnableQualityOptionsStylingChanges);

    const onColorOptionChanged = (
      selectedOptionIds: number[],
      changeData: ProductOptionsChangeData,
      optionId: string
    ) => {
      this.setState({latestActiveOptionId: optionId});
      void onSelectionIdsChange(selectedOptionIds, changeData);
    };

    return colorOptions.map((option) => {
      return (
        <div
          key={option.id}
          className={classNames(
            this.getColorOptionClass(shouldUseNewMarginAndPadding),
            this.getVisibilityClassNames(option)
          )}
          data-hook={DataHook.OptionWrapper}>
          <ProductColorOption
            url={productsManifest[product.id].url}
            option={option}
            availability={variantsAvailability[option.id]}
            selectionIds={selectionIds}
            onSelectionIdsChange={(selectedOptionIds: number[], changeData: ProductOptionsChangeData) =>
              onColorOptionChanged(selectedOptionIds, changeData, option.id)
            }
            isLoading={this.shouldRenderLoader(option.id)}
          />
        </div>
      );
    });
  };

  private readonly getVisibilityClassNames = (option: IProductOption) => {
    return classNames({
      [s.lastVisibleOption]: (this.isVisible(option) || this.isFirst(option)) && !this.isNextVisible(option),
    });
  };

  private readonly getDropDownOptionClass = (isNewMargin: boolean): string =>
    isNewMargin ? s.dropdown : s.oldDropdown;

  public readonly renderAllDropdownOptions = (dropdownOptions: IProductOption[]) => {
    const {variantsAvailability, globals} = this.props;
    const {isOptionsRevealEnabled: shouldIncludeVisibleKey} = globals;
    const shouldUseNewMargin = this.props.globals.useExperiments.enabled(
      Experiments.EnableQualityOptionsStylingChanges
    );

    return dropdownOptions.map((option) =>
      this.wrapAnimaiton(
        <div
          key={`${option.id}${shouldIncludeVisibleKey ? this.isVisible(option) : ''}`}
          className={classNames(this.getDropDownOptionClass(shouldUseNewMargin), this.getVisibilityClassNames(option))}
          data-hook={DataHook.OptionWrapper}>
          {this.renderDropdownOption({option, availability: variantsAvailability[option.id]})}
        </div>,
        this.isVisible(option),
        this.isFirst(option)
      )
    );
  };

  private wrapAnimaiton(child: ReactNode, isVisible: boolean, preserveSpace: boolean): ReactNode {
    return this.props.globals.isOptionsRevealEnabled ? (
      <RevealAnimation isVisible={isVisible} preserveSpace={preserveSpace} data-hook={DataHook.RevealAnimationWrapper}>
        {child}
      </RevealAnimation>
    ) : (
      child
    );
  }

  private isOptionSelected(option: IProductOption): boolean {
    return option.selections.find(({id}) => this.props.selectionIds.includes(id)) !== undefined;
  }

  private isFirst(option: IProductOption): boolean {
    const options = getAllVisibleOptions(this.props.product.options);
    return options.length && options[0].id === option.id;
  }

  private isVisible(option: IProductOption): boolean {
    const options = getAllVisibleOptions(this.props.product.options);
    const index = options.findIndex(({id}) => option.id === id);
    return (
      this.isOptionSelected(option) ||
      (this.isFirst(option) && this.props.isItemHovered) ||
      (options[index] && this.isOptionSelected(options[index]))
    );
  }

  private isNextVisible(option: IProductOption): boolean {
    const options = getAllVisibleOptions(this.props.product.options);
    const index = options.findIndex(({id}) => option.id === id);
    return options[index + 1] && this.isVisible(options[index + 1]);
  }

  public render() {
    const options = this.props.product.options;
    const colorOptions = getAllVisibleColorOptions(options);
    const dropdownOptions = getAllVisibleDropdownOptions(options);

    return (
      <>
        {this.renderAllColorOptions(colorOptions)}
        <ConditionalRender by={'showDropdownOptions'}>
          {this.renderAllDropdownOptions(dropdownOptions)}
        </ConditionalRender>
      </>
    );
  }
}

export const ProductOptionsWithGlobals = withGlobals(withTranslations()(ProductOptions));
