/* istanbul ignore file: todo(eran): not running image tests on this file yet */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable sonarjs/cognitive-complexity */

import React, {useRef} from 'react';
import {ImageUiTpaWrapper} from './ImageUiTpaWrapper';
import s from './ProductMedia.scss';
import {HoverType, ImageModeId, ImageRatioId, IProduct, ProductType} from '../../../../types/galleryTypes';
import {ImageResizeOptions} from 'wix-ui-tpa';
import {DigitalProductBadge} from '@wix/wixstores-client-common-components/dist/es/src/icons/dist';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {Carousel, CarouselAriaLabels} from '../../Carousel/Carousel';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import {ConditionalRender} from '../../ConditionalRender/ConditionalRender';

export interface IProductImageProps extends IGalleryGlobalProps {
  product: IProduct;
  isMobile: boolean;
  hoverType: string;
  imageModeId: number;
  classNames: {thumbnail: string; image: string};
  children?: React.ReactChild[] | React.ReactChild;
  textsMap: {
    digitalProductBadgeAriaLabel: string;
  };
}

export enum ProductMediaDataHook {
  Images = 'ProductMediaDataHook.Images',
  ProductMedia = 'ProductMediaDataHook.ProductMedia',
  Badge = 'ProductMediaDataHook.Badge',
}

export const MAX_CAROUSEL_IMAGES = 5;
export const getProductMediaDataHook = (key: number) => `${ProductMediaDataHook.ProductMedia}-${key}`;

export const ratioIdToRatioMap = {
  [ImageRatioId._3x2]: 3 / 2,
  [ImageRatioId._4x3]: 4 / 3,
  [ImageRatioId._1x1]: 1,
  [ImageRatioId._3x4]: 3 / 4,
  [ImageRatioId._2x3]: 2 / 3,
  [ImageRatioId._16x9]: 16 / 9,
  [ImageRatioId._9x16]: 9 / 16,
};

const ProductImageComponent: React.FC<IProductImageProps & IProvidedTranslationProps> = (
  props: IProductImageProps & IProvidedTranslationProps
) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const renderDigitalBadge = () => {
    return (
      <div
        className={s.productBadge}
        data-hook={ProductMediaDataHook.Badge}
        aria-label={props.textsMap.digitalProductBadgeAriaLabel}>
        <DigitalProductBadge />
      </div>
    );
  };

  const imageRatio = () => {
    const {
      globals: {styles, stylesParams, shouldShowMobile},
    } = props;
    return styles.get(stylesParams[shouldShowMobile ? 'mobile:galleryImageRatio' : 'galleryImageRatio']);
  };

  const renderImages = () => {
    const {
      hoverType,
      imageModeId,
      isMobile,
      product,
      classNames: {thumbnail: externalThumbnailClass, image: externalImageClass},
      globals: {shouldShowImageCarousel},
    } = props;

    let showAmountOfPhotos: number = 1;

    const shouldShowSecondaryImageByHoverType = !isMobile && hoverType === HoverType.Alternate;

    if (product.media.length >= 2) {
      if (shouldShowImageCarousel) {
        showAmountOfPhotos = Math.min(product.media.length, MAX_CAROUSEL_IMAGES);
      } else if (shouldShowSecondaryImageByHoverType) {
        showAmountOfPhotos = 2;
      }
    }

    return Array.from({length: showAmountOfPhotos}, (_value, key) => (
      <ImageUiTpaWrapper
        hoverType={hoverType as HoverType}
        mediaItemIndex={key}
        key={key}
        globals={props.globals}
        wrapperClassName={externalThumbnailClass}
        imageClassName={externalImageClass}
        product={product}
        data-hook={getProductMediaDataHook(key)}
        resize={imageModeId === ImageModeId.Crop ? ImageResizeOptions.cover : ImageResizeOptions.contain}
        containerAspectRatio={ratioIdToRatioMap[imageRatio()]}
      />
    ));
  };

  const {
    product,
    globals: {shouldShowImageCarousel, isLiveSiteMode, isPreviewMode, isEditorMode},
    t,
  } = props;

  const ariaLabels: CarouselAriaLabels = {
    arrowLeft: t('arrowPrevious'),
    arrowRight: t('arrowNext'),
  };

  const shouldWrapWithImageCarousel = shouldShowImageCarousel && (product.media.length > 1 || isEditorMode);

  const renderedImages = renderImages();

  return (
    <div
      className={classNames(s.productImages, 'heightByImageRatio', `heightByImageRatio${imageRatio()}`)}
      ref={imageContainerRef}
      aria-live={'assertive'}
      data-hook={ProductMediaDataHook.Images}>
      {shouldWrapWithImageCarousel ? (
        <Carousel
          ariaLabels={ariaLabels}
          shouldShowArrowsOnHover={isLiveSiteMode || isPreviewMode}
          imageCount={renderedImages.length}>
          {renderedImages}
        </Carousel>
      ) : (
        renderedImages
      )}
      {product.productType === ProductType.DIGITAL ? (
        <ConditionalRender by={'gallery_showDigitalBadge'}>{renderDigitalBadge()}</ConditionalRender>
      ) : (
        product.productType === ProductType.DIGITAL && renderDigitalBadge()
      )}
      {props.children}
    </div>
  );
};

export const ProductMedia = withTranslations()(ProductImageComponent);
